<template>
    <div class="breadcrumb">
        <!-- had to escape the < lol -->
        <!-- edit: ???? -->
        <p>
            <img src="../../assets/img/icon-dropdown.svg">
        </p>
        <router-link :to="{ name: 'events' }">EVENTS</router-link>
        <p class="spacer">-</p>
        <a id="event-link" @click="goToEvent()"></a>
        <p class="spacer">-</p>
        <router-link v-if="this.$route.name == 'product'" :to="{ name: 'wines' }">WINES</router-link>
        <router-link v-if="this.$route.name == 'exhibitor'" :to="{ name: 'importers' }">IMPORTERS</router-link>
        <p  v-if="this.$route.name == 'exhibitor' || this.$route.name == 'product'" class="spacer">-</p>
        <p>{{ item }}</p>
    </div>
</template>
<script>
import store from '@/store/index.js'
import $ from 'jquery'

export default {
    name: 'Breadcrumb',

    data() {
        return {
            eventSlug: String,
            eventId: String
        }
    },

    props: {
        item: String
    },

    methods: {
        goToEvent() {
            this.$router.push({name:'event', params:{slug:this.eventSlug, eventId: this.eventId}})
        },
    },

    beforeMount() {
        this.eventSlug  = store.getters.getvisitedEvent;
        this.eventId    = store.getters.getvisitedEventId;
    },

    mounted() {
        $('#event-link').text(this.eventSlug.replaceAll("-", " "));
    },
}
</script>