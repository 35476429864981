<template>
<div class="account">
    <div v-if="this.isFetching" class="account-loading">
        <div class="lds-ring">
            <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
        </div>
    </div>
    <div v-else class="account">
        <Breadcrumb v-bind:item="this.titles[0]"/>

        
        <div class="account-wrap">
            <div class="col">
                <div class="account-pfp">
                    <img v-if="this.user.profilePicture.length > 0" :src="this.user.profilePicture[0].url" alt="">
                    <p v-else class="pfp-initials unselectable">{{ getInitials(this.user.fullName) }}</p>

                </div>
                <!-- <div class="btn-edit">
                    <label for="upload-photo">EDIT</label>
                    <input type="file" name="photo" id="upload-photo" />
                </div> -->
            </div>

            <div class="col">
                <SectionHeader v-bind:title="this.titles[0]"/>

                <div class="account-grid">

                    <div class="account-info">
                        <p class="info-name">FIRST NAME</p>
                        <p class="info-value">{{this.user.firstName}}</p>

                        <p class="info-name">SURNAME</p>
                        <p class="info-value">{{this.user.lastName}}</p>

                        <p class="info-name">USERNAME</p>
                        <p class="info-value">{{this.user.username}}</p>
                    
                        <p class="info-name">EMAIL</p>
                        <p class="info-value">{{this.user.email}}</p>

                        <p class="info-name">BIO</p>
                        <p class="info-value" id="user-bio">{{this.user.bio | striphtml}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


</template>
<script>
import Breadcrumb from '@/components/snippets/Breadcrumb.vue'
import SectionHeader from '@/components/snippets/SectionHeader.vue'

import gql from 'graphql-tag'


export default {
    name: 'exhibitor',

    components: { 
        SectionHeader,
        Breadcrumb
    },

    data() {
        return {
            titles: [
                'ACCOUNT',
                'CHANGE YOUR PASSWORD',
                'PRIVACY'
            ],
            isFetching: true,
            userID: localStorage.getItem('userID'),
            user: []
        }
    },

    methods: {
        getInitials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

            let initials = [...name.matchAll(rgx)] || [];

            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials
        }
    },
    
    apollo: {
        user: {
            client: 'craft',
            httpEndpoint: 'https://californiawines.co.uk/web/api',
            query: gql`query ($userID: [QueryArgument]) {
                user(id: $userID) {
                    ... on User {
                        id
                        email
                        profilePicture {
                            ... on user_Asset {
                            id
                            url
                            }
                        }
                        firstName
                        lastName
                        fullName
                        bio
                        username
                    }
                }
            }`,

            variables() {
                return {
                    userID: this.userID
                }
            },

            result ({ data, loading }) {

                if (!loading) {
                    // assign all products to data.products
                    this.user = data.user
                    this.isFetching = false;
                }
            },
        }
    }

}
</script>